(function(w, d) {
    function calcWallpaper() {
        var container = d.getElementById('main-page-container'),
            combinedAdWidth = w.innerWidth - parseInt(w.getComputedStyle(container).width),
            side = (combinedAdWidth / 2) - 10;

        d.getElementById('wallpaper-left').style.width = side + 'px';
        d.getElementById('wallpaper-right').style.width = side + 'px';
    }

    // Document resize
    w.addEventListener('resize', function() {
        calcWallpaper();
    });

    // Document ready
    if (d.readyState == 'loading') {
        w.addEventListener('DOMContentLoaded', function() {
            calcWallpaper();
        });
    } else {
        calcWallpaper();
    }
})(window, document);